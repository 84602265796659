<app-breadcrumb currentPage="Monitoring" [pages]="[{title: 'Početna', link: '/dashboard'}]"></app-breadcrumb>
<div class="header-row">
  <div class="header">Monitoring sistema</div>
</div>

<div class="table-container">
<!--  <mat-grid-list cols="6">-->
<!--    <mat-grid-tile *ngFor="let chart of charts" [colspan]="chart.cols" [rowspan]="chart.rows">-->
<!--      <div style="margin-bottom: 30px" [innerHtml]="sanitizeHtml(chart.frame)"></div>-->
<!--    </mat-grid-tile>-->
<!--  </mat-grid-list>-->
  <div style="margin-bottom: 30px" [innerHtml]="sanitizeHtml(grafana)"></div>
</div>
