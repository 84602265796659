import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReportsService } from '@app/core/services/reports.service';
import {
  Observable,
  Subscription,
  delay,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  REPORT_STATUS,
  Report,
  ReportExecution,
  ReportGenerateData,
} from '@app/shared/types';
import { ReportsPreviewModalComponent } from '../reports-preview-modal/reports-preview-modal.component';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-report-generate-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: './report-generate-modal.component.html',
  styleUrls: ['./report-generate-modal.component.css'],
})
export class ReportGenerateModalComponent {
  public runReportSub: Subscription;
  public timeoutId: ReturnType<typeof setTimeout>;

  constructor(
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private readonly reportsService: ReportsService,
    public dialogRef: MatDialogRef<ReportGenerateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportGenerateData
  ) {}

  ngOnInit(): void {
    this.runReportSub = this.reportsService
      .runReport(this.data.report)
      .pipe(
        switchMap((res) => this.checkReportStatus(res.requestId)),
        switchMap((res) =>
          this.reportsService.getReportOutput(res.requestId, res.exports[0].id)
        )
      )
      .subscribe((file) => {
        this.data.isPreview
          ? this.openPreviewModal(file, this.data.report)
          : this.downloadReport(file);
        this.dialogRef.close();
      });

    this.defaultTimeout();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    this.runReportSub.unsubscribe();
  }

  checkReportStatus(requestId: string): Observable<ReportExecution> {
    return this.reportsService.getReportExecutionStatus(requestId).pipe(
      delay(2000),
      switchMap((res) => {
        if (res.status === REPORT_STATUS.FAILED) {
          this.dialogRef.close();
          this.snackBar.open(`${res.errorDescriptor?.message}`, undefined, {
            duration: 5000,
          });
          return throwError(() => new Error(`${res.errorDescriptor?.message}`));
        }
        if (res.status !== REPORT_STATUS.READY) {
          return this.checkReportStatus(requestId);
        }
        return of(res);
      })
    );
  }

  defaultTimeout(): void {
    this.timeoutId = setTimeout(() => {
      this.dialogRef.close();
      this.snackBar.open(
        `There was an error in execution of this report`,
        undefined,
        {
          duration: 5000,
        }
      );
    }, 60000);
  }

  openPreviewModal(file: BlobPart, report: Report): void {
    this.dialog.open(ReportsPreviewModalComponent, {
      width: '800px',
      height: '750px',
      hasBackdrop: false,
      autoFocus: false,
      data: {
        file,
        report,
      },
    });
  }

  downloadReport(file: Blob): void {
    saveAs(file, `report-${Date.now()}.${this.data.fileType}`);
  }
}
