import { ComponentType } from '@angular/cdk/portal';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { SingleValueBooleanComponent } from './single-value-boolean/single-value-boolean.component';
import { SingleValueNumberComponent } from './single-value-number/single-value-number.component';
import { MultiSelectCheckboxComponent } from './multi-select-checkbox/multi-select-checkbox.component';
import { SingleSelectComponent } from './single-select/single-select.component';
import { SingleSelectRadioComponent } from './single-select-radio/single-select-radio.component';
import { SingleValueDateComponent } from './single-value-date/single-value-date.component';
import { SingleValueDatetimeComponent } from './single-value-datetime/single-value-datetime.component';
import { SingleValueTimeComponent } from './single-value-time/single-value-time.component';
import { SingleValueTextComponent } from './single-value-text/single-value-text.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ControlsMap = Record<string, ComponentType<any>>;
export const CONTROLS_MAP: ControlsMap = {
  ['bool']: SingleValueBooleanComponent,
  ['multiSelect']: MultiSelectComponent,
  ['multiSelectCheckbox']: MultiSelectCheckboxComponent,
  ['singleSelect']: SingleSelectComponent,
  ['singleSelectRadio']: SingleSelectRadioComponent,
  ['singleValueNumber']: SingleValueNumberComponent,
  ['singleValueDate']: SingleValueDateComponent,
  ['singleValueDatetime']: SingleValueDatetimeComponent,
  ['singleValueTime']: SingleValueTimeComponent,
  ['singleValueText']: SingleValueTextComponent,
};
