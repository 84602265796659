import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReportsService } from '@app/core/services/reports.service';
import {
  InputControls,
  Report,
  ReportDetailsData,
  ReportUnit,
} from '@app/shared/types';
import { CONTROLS_MAP } from '../controls/controls.utils';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ReportGenerateModalComponent } from '../report-generate-modal/report-generate-modal.component';
import { Subscription, debounceTime, switchMap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

interface ComponentOutput {
  id: string;
  value: string | string[];
}

@Component({
  selector: 'app-report-details',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css'],
})
export class ReportDetailsComponent {
  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;
  public report: ReportUnit;
  public selectedData: ComponentOutput[] = [];
  public getInputControlValuesSub: Subscription;
  public componentOutputSub: Subscription;

  constructor(
    private dialog: MatDialog,
    private readonly reportsService: ReportsService,
    public dialogRef: MatDialogRef<ReportDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportDetailsData
  ) {
    this.report = data.report;
  }

  ngOnInit(): void {
    this.getInputControlValuesSub = this.reportsService
      .getInputControlValues(this.report.uri)
      .subscribe((res) => {
        this.refreshView(res);
      });
  }

  ngOnDestroy(): void {
    this.getInputControlValuesSub.unsubscribe();
    this.componentOutputSub.unsubscribe();
  }

  refreshView(inputControls: InputControls): void {
    inputControls.inputControl.forEach((control) => {
      const componentRef = this.container.createComponent(
        CONTROLS_MAP[control.type]
      );
      componentRef.setInput('data', {
        state: control.state,
        label: control.label,
      });
      this.componentOutputSub = componentRef.instance.output
        ?.pipe(
          debounceTime(700),
          switchMap((res: ComponentOutput) => {
            if (!this.selectedData.some((el) => el.id === res.id)) {
              this.selectedData = [...this.selectedData, res];
            } else {
              const index = this.selectedData.findIndex(
                (obj) => obj.id === res.id
              );
              this.selectedData.splice(index, 1, res);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const body: any = {};
            this.selectedData.forEach((el) => {
              body[`${el.id}`] = [el.value];
            });
            return this.reportsService.refreshInputControlValues(
              this.report.uri,
              body
            );
          })
        )
        .subscribe((res: InputControls) => {
          this.container.clear();
          this.refreshView(res);
        });
    });
  }

  generateReport(): void {
    const report: Report = {
      reportUnitUri: this.data.report.uri,
      async: true,
      freshData: false,
      saveDataSnapshot: false,
      outputFormat: 'pdf',
      interactive: true,
      ignorePagination: false,
      parameters: {
        reportParameter: this.selectedData.map((data) => {
          return {
            name: data.id,
            value: Array.isArray(data.value) ? data.value : [data.value],
          };
        }),
      },
    };

    this.dialog.open(ReportGenerateModalComponent, {
      width: '300px',
      height: '300px',
      data: {
        report: report,
        reportName: this.report.label,
        isPreview: true,
      },
      disableClose: true,
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
