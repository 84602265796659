import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from './permission.service';

export const PermissionGuard: CanActivateFn = (route) => {
  const userRole = localStorage.getItem('role');
  const router = inject(Router);
  const permissionService = inject(PermissionService);
  if (!!userRole && permissionService.checkPermission(route.data['feature'])) {
    return true;
  } else {
    router.navigate(['dashboard']);
    return false;
  }
};
