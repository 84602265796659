import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import {
  Report,
  InputControls,
  ReportDetails,
  ResourceLookup,
  ReportExecution,
} from '@app/shared/types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private httpClient: HttpClient) {}

  getAllReports(): Observable<ResourceLookup> {
    return this.httpClient.get<ResourceLookup>(
      `${environment.reportsUrl}/rest/getListOfReports?type=reportUnit`
    );
  }

  getReportDetails(reportLabel: string): Observable<ReportDetails> {
    return this.httpClient.get<ReportDetails>(
      `${environment.reportsUrl}/rest/getResourcesOfReport?uri=/reports/Interactive/${reportLabel}`
    );
  }

  getInputControlValues(reportUri: string): Observable<InputControls> {
    return this.httpClient.get<InputControls>(
      `${environment.reportsUrl}/rest/listingInputControlStructure?pathToReport=${reportUri}/`
    );
  }

  refreshInputControlValues(
    pathToReport: string,
    body: unknown
  ): Observable<InputControls> {
    return this.httpClient.post<InputControls>(
      `${environment.reportsUrl}/rest/listingInputControlStructure?pathToReport=${pathToReport}/`,
      body
    );
  }

  runReport(reportParams: Report): Observable<ReportExecution> {
    return this.httpClient.post<ReportExecution>(
      `${environment.reportsUrl}/rest/reportExecutions`,
      reportParams
    );
  }

  getReportExecutionStatus(reportId: string): Observable<ReportExecution> {
    return this.httpClient.get<ReportExecution>(
      `${environment.reportsUrl}/rest/executionStatus?requestId=${reportId}`
    );
  }

  getReportOutput(reportId: string, exportId: string): Observable<Blob> {
    return this.httpClient.get(
      `${environment.reportsUrl}/rest/requestingReportOutput?requestId=${reportId}&exportId=${exportId}`,
      {
        responseType: 'blob',
      }
    );
  }
}
