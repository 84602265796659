import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OptionState } from '@app/shared/types';

interface MultiSelectElement {
  id: string;
  value: string[];
}
@Component({
  selector: 'app-multi-select-checkbox',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.css'],
})
export class MultiSelectCheckboxComponent {
  @Input() public data: {
    state: OptionState;
    label: string;
  };
  @Output() output = new EventEmitter<MultiSelectElement>();
  public currentState: string[] = [];

  onDataChange(checked: boolean, value: string) {
    if (checked) {
      if (!this.currentState.some((el) => el === value)) {
        this.currentState = [...this.currentState, value];
      }
    }
    if (!checked) {
      const index = this.currentState.findIndex((obj) => obj === value);
      this.currentState.splice(index, 1);
    }

    this.output.emit({
      id: this.data.state.id,
      value: this.currentState,
    });
  }
}
