<form
  [formGroup]="loginForm"
  (ngSubmit)="loginUser()"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="10"
  class="login-form"
>
  <section fxLayout="row" fxLayoutAlign="start end">
    <img src="assets/img/grb.jpg" width="200" height="250" />
    <article fxLayout="column">
      <img src="assets/img/elab-logo.svg" />
      <span class="logo-title">MINISTARSTVO ZDRAVLJA REPUBLIKE SRBIJE</span>
    </article>
  </section>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Korisničko ime</mat-label>
    <input matInput placeholder="Korisničko ime" formControlName="username" />
  </mat-form-field>

  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Lozinka</mat-label>
    <input
      matInput
      placeholder="Lozinka"
      formControlName="password"
      type="password"
    />
  </mat-form-field>

  <button mat-button color="primary" [disabled]="!this.loginForm.valid">
    PRIJAVA
  </button>
</form>
