import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatGridListModule} from "@angular/material/grid-list";
import {BreadcrumbComponent} from "@layout/shared/components/breadcrumb/breadcrumb.component";
import {Chart} from "@shared/types/Common.types";
import {environment} from "@environments/environment";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-monitoring',
  standalone: true,
  imports: [CommonModule, MatGridListModule, BreadcrumbComponent],
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
export class MonitoringComponent {
  grafana = `<iframe src="${environment.grafanaUrl}/d/rYdddlPWk/node-exporter-full?orgId=1&theme=light&kiosk" width="100%" height="2000" frameborder="0"></iframe>`
  charts: Chart[] = [
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&theme=light&panelId=20" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694185828553&to=1694272228553&theme=light&panelId=155" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame:  `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694185828553&to=1694272228553&theme=light&panelId=19" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694185828553&to=1694272228553&theme=light&panelId=16" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694185828553&to=1694272228553&theme=light&panelId=21" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694185828553&to=1694272228553&theme=light&panelId=154" width="200" height="170" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182653515&to=1694269053515&theme=light&panelId=3" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182688078&to=1694269088078&theme=light&panelId=24" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182729413&to=1694269129413&theme=light&panelId=84" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182688078&to=1694269088078&theme=light&panelId=229" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182688078&to=1694269088078&theme=light&panelId=42" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/rYdddlPWk/node-exporter-full?orgId=1&from=1694182688078&to=1694269088078&theme=light&panelId=127" width="600" height="370" frameborder="0"></iframe>`, cols: 3, rows: 2},
  ];

  constructor(private sanitizer: DomSanitizer) { }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
