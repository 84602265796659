<div mat-dialog-title class="dialog-title">
  <h2>{{ report.label }}</h2>
  <button
    type="button"
    mat-icon-button
    class="close-button"
    (click)="closeModal()"
  >
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<section
  mat-dialog-content
  fxLayout="column"
  fxLayoutAlign="space-between start"
  class="reports-details"
>
  <section class="controls-container">
    <ng-container #container></ng-container>
  </section>
</section>

<section
  mat-dialog-actions
  class="button-container"
  fxLayout="row"
  fxLayoutAlign="end end"
  fxLayoutGap="10"
>
  <button (click)="generateReport()" mat-flat-button color="primary">
    Generisanje izveštaja
  </button>

  <button mat-dialog-close mat-flat-button color="primary">Zatvori</button>
</section>
