import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueState } from '@app/shared/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

interface SingleValueTextOutput {
  id: string;
  value: string;
}
@Component({
  selector: 'app-single-value-text',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './single-value-text.component.html',
  styleUrls: ['./single-value-text.component.css'],
})
export class SingleValueTextComponent {
  @Input() public data: {
    state: ValueState;
    label: string;
  };
  @Output() output = new EventEmitter<SingleValueTextOutput>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange(value: any) {
    this.output.emit({
      id: this.data.state.id,
      value: value.target.value,
    });
  }
}
