import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import { LoginResponse, UserAuth } from '@app/shared/types/Auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) {}

  public login(user: UserAuth) {
    console.log(environment);
    return this.http.post<LoginResponse>(
      `${environment.apiUrl}/rest/getAccessToken`,
      user
    );
  }

  public logout(): void {
    const removeToken = localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    if (removeToken == null) {
      this.router.navigate(['login']);
    }
  }

  public getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  public getRole(): string | null {
    return localStorage.getItem('role');
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get reportsUrl(): string {
    return environment.reportsUrl;
  }

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem('access_token');
    return authToken !== null;
  }
}
