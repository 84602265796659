import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {
  UserDialogData,
  UserResponse,
  UserType,
} from '@shared/types/User.types';
import { UserService } from '@core/services/user.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UserModalComponent } from '@layout/users/modal/user-modal.component';
import { ConfirmationDialogComponent } from '@layout/shared/components/confirmation-dialog/confirmation-dialog.component';
import { BreadcrumbComponent } from '@layout/shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
    UserModalComponent,
    BreadcrumbComponent,
  ],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'username',
    'orgName',
    'typeUcode',
    'actions',
  ];
  users = new MatTableDataSource<UserResponse>([]);
  userTypes: UserType[] = [];
  colored = '';

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.userService.getAllUserTypes().subscribe({
      next: (res) => {
        this.userTypes = res;
        this.loadUsers();
      },
      error: (err) => {
        console.error('Something wrong occurred: ' + err);
      },
    });
  }

  loadUsers() {
    this.userService.getAllUsers().subscribe({
      next: (res) => {
        const sorted = res.sort((a, b) => {
          // Compare by firstName first
          const firstNameComparison = a.firstName?.localeCompare(b.firstName);

          // If firstName is the same, compare by lastName
          if (firstNameComparison === 0) {
            return a.lastName?.localeCompare(b.lastName);
          }

          return firstNameComparison;
        });
        this.users = new MatTableDataSource<UserResponse>(sorted);
      },
      error: (err) => {
        console.error('Something wrong occurred: ' + err);
      },
    });
  }

  viewUser(user: UserResponse) {
    const data = {} as UserDialogData;
    data.create = false;
    data.username = user.userName;
    data.userTypes = this.userTypes;
    this.dialog
      .open(UserModalComponent, {
        width: '500px',
        data: data,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data == true) {
          this.loadUsers();
        } else if (data.edited) {
          this.loadUsers();
          this.colored = user.userName;
        }
      });
  }

  createUser() {
    const data = {} as UserDialogData;
    data.create = true;
    data.userTypes = this.userTypes;
    this.dialog
      .open(UserModalComponent, {
        width: '500px',
        data: data,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data == true) {
          this.loadUsers();
        }
      });
  }

  deleteUser(user: UserResponse) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.userService.deleteUser(user.userName).subscribe({
          next: () => {
            this.loadUsers();
          },
          error: (err) => {
            0;
            console.error('Something wrong occurred: ' + err);
          },
        });
      }
    });
  }

  getTypeName(ucode: string) {
    for (const type of this.userTypes) {
      if (type.ucode == ucode) {
        return type.name;
      }
    }
    return '';
  }
}
