<app-breadcrumb
  currentPage="Korisnici"
  [pages]="[{ title: 'Početna', link: '/dashboard' }]"
></app-breadcrumb>
<div class="header-row">
  <div class="header">Korisnici</div>
  <button (click)="createUser()" mat-flat-button color="primary" class="button">
    Novi korisnik
  </button>
</div>
<div class="table-container">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="users" class="mat-elevation-z0">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Ime</th>
        <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Prezime</th>
        <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Korisničko ime</th>
        <td mat-cell *matCellDef="let user">{{ user.userName }}</td>
      </ng-container>

      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef>Organizacija</th>
        <td mat-cell *matCellDef="let user">{{ user.orgName }}</td>
      </ng-container>

      <ng-container matColumnDef="typeUcode">
        <th mat-header-cell *matHeaderCellDef>Tip korisnika</th>
        <td mat-cell *matCellDef="let user">
          {{ getTypeName(user.typeUcode) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Akcije</th>
        <td mat-cell *matCellDef="let user">
          <button
            style="margin-right: 5px"
            mat-button
            color="primary"
            (click)="viewUser(user)"
          >
            Detalji
          </button>
          <button
            *ngIf="user.typeUcode != 'APP_USER'"
            mat-button
            color="warn"
            (click)="deleteUser(user)"
          >
            Obriši
          </button>
        </td>
      </ng-container>

      <!-- Table rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row-color"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'highlighted-row': colored == row.userName}"></tr>
    </table>
  </div>
</div>
