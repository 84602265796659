import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Institution } from '@app/shared/types/Institutions.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstitutionsService {
  constructor(private httpClient: HttpClient) {}

  getInstitutions(offset: number, limit: number): Observable<Institution[]> {
    return this.httpClient.get<Institution[]>(
      `${environment.apiUrl}/rest/ElabCases/getInstitutions?offset=${offset}&limit=${limit}`
    );
  }
}
