import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Option, OptionState } from '@app/shared/types';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

interface SingleSelectOutput {
  id: string;
  value: string;
}
@Component({
  selector: 'app-single-select',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.css'],
})
export class SingleSelectComponent {
  public selected: Option;
  @Input() public data: {
    state: OptionState;
    label: string;
  };
  @Output() output = new EventEmitter<SingleSelectOutput>();

  ngOnInit(): void {
    this.selected = this.data.state.options.find((option) => option.selected)!;
  }

  onDataChange(value: string) {
    this.output.emit({
      id: this.data.state.id,
      value: value,
    });
  }
}
