<section>
  <h4>{{ data.label }}</h4>
  <mat-form-field class="mat-number">
    <mat-label>{{ data.label }}</mat-label>
    <input
      matInput
      type="number"
      [value]="data.state.value"
      (input)="onDataChange($event)"
    />
  </mat-form-field>
</section>
