import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionState } from '@app/shared/types';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
interface SingleSelectRadioOutput {
  id: string;
  value: string;
}
@Component({
  selector: 'app-single-select-radio',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FlexLayoutModule],
  templateUrl: './single-select-radio.component.html',
  styleUrls: ['./single-select-radio.component.css'],
})
export class SingleSelectRadioComponent {
  @Input() public data: {
    state: OptionState;
    label: string;
  };
  @Output() output = new EventEmitter<SingleSelectRadioOutput>();

  onDataChange(value: string) {
    this.output.emit({
      id: this.data.state.id,
      value: value,
    });
  }
}
