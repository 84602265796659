import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-single-value-time',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-value-time.component.html',
  styleUrls: ['./single-value-time.component.css'],
})
export class SingleValueTimeComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public data: any;

  constructor() {}

  ngOnInit(): void {}
}
