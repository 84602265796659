import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionState } from '@app/shared/types';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

interface MultiSelectOutput {
  id: string;
  value: string[];
}
@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css'],
})
export class MultiSelectComponent {
  @Input() public data: {
    state: OptionState;
    label: string;
  };
  @Output() output = new EventEmitter<MultiSelectOutput>();

  onDataChange(value: string[]) {
    this.output.emit({
      id: this.data.state.id,
      value: value,
    });
  }
}
