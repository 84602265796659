<div class="preview-modal">
  <section mat-dialog-title class="dialog-title">
    <h2>Pregled izveštaja</h2>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="closeModal()"
    >
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </section>
  <section mat-dialog-content class="pdf-viewer-container">
    <pdf-viewer
      *ngIf="pdfSrc"
      class="pdf-viewer"
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="false"
      [zoom]="zoom"
    ></pdf-viewer>
  </section>
  <section mat-dialog-actions class="button-container">
    <article class="zoom">
      <div class="zoom-container">
        <div class="number">
          <span class="minus" (click)="zoomOut()">-</span>
          <input type="text" [value]="zoomLabel" disabled />
          <span class="plus" (click)="zoomIn()">+</span>
        </div>
      </div>

      <mat-form-field class="select" appearance="outline">
        <mat-label>Tip fajla za čuvanje</mat-label>
        <mat-select
          (selectionChange)="onFileTypeChange($event.value)"
          [(value)]="fileType"
        >
          <mat-option value="pdf">pdf</mat-option>
          <mat-option value="docx">docx</mat-option>
          <mat-option value="xlsx">xlsx</mat-option>
        </mat-select>
      </mat-form-field>
    </article>
    <article>
      <button
        (click)="generateReportForDownload()"
        mat-flat-button
        color="primary"
      >
        Sačuvaj izveštaja
      </button>
      <button mat-dialog-close mat-flat-button color="primary">Zatvori</button>
    </article>
  </section>
</div>
