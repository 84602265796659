export interface UserAuth {
  username?: string | null | undefined;
  password?: string | null | undefined;
}

export interface LoginResponse {
  id: string;
  code: string;
  message: string;
}
