<section
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40"
  class="generate-modal"
>
  <article fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10">
    <mat-spinner></mat-spinner>
    <span>Generisanje izveštaja</span>
  </article>
  <button
    mat-dialog-close
    mat-flat-button
    color="primary"
    class="generate-modal-close"
  >
    Zatvori
  </button>
</section>
