<section fxLayout="column" class="single-select-radio">
  <h4>{{ data.label }}</h4>
  <mat-radio-group fxLayout="column">
    <mat-radio-button
      *ngFor="let option of data.state.options"
      [value]="option.value"
      (change)="onDataChange($event.value)"
    >
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>
</section>
