import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ValueState } from '@app/shared/types';
import { FlexLayoutModule } from '@angular/flex-layout';

interface SingleValueBooleanOutput {
  id: string;
  value: boolean;
}
@Component({
  selector: 'app-single-value-boolean',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FlexLayoutModule],
  templateUrl: './single-value-boolean.component.html',
  styleUrls: ['./single-value-boolean.component.css'],
})
export class SingleValueBooleanComponent {
  @Input() public data: {
    state: ValueState;
    label: string;
  };
  @Output() output = new EventEmitter<SingleValueBooleanOutput>();

  onDataChange(isChecked: boolean) {
    this.output.emit({
      id: this.data.state.id,
      value: isChecked,
    });
  }
}
