import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Features } from '@app/shared/types';
import { PermissionService } from './permission.service';

@Directive({
  standalone: true,
  selector: '[checkPermissions]',
})
export class CheckPermissionsDirective implements OnInit, OnDestroy {
  @Input() checkPermissions: string = Features.DASHBOARD;
  userRole = localStorage.getItem('role');

  private templateRef = inject(TemplateRef<unknown>);
  private viewContainerRef = inject(ViewContainerRef);
  private permissionService = inject(PermissionService);

  ngOnInit() {
    if (
      !!this.userRole &&
      this.permissionService.checkPermission(this.checkPermissions as Features)
    ) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  ngOnDestroy() {}
}
