<h1 mat-dialog-title>Detalji o korisniku</h1>
<button
  type="button"
  mat-icon-button
  class="close-button"
  (click)="closeModal()"
>
  <mat-icon class="close-icon">close</mat-icon>
</button>
<p class="ml-10">Dodatne informacije o korisniku</p>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="example-form">
  <div mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Ime</mat-label>
      <input matInput formControlName="firstName" placeholder="Ime" />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Prezime</mat-label>
      <input matInput formControlName="lastName" placeholder="Prezime" />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>E-mail adresa</mat-label>
      <input matInput formControlName="email" placeholder="E-mail adresa" />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Organizacija</mat-label>
      <input matInput formControlName="orgName" placeholder="Organizacija" />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Korisničko ime</mat-label>
      <input
        [disabled]="disableFields()"
        [readOnly]="disableFields()"
        matInput
        formControlName="userName"
        placeholder="Korisničko ime"
      />
    </mat-form-field>
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Lozinka</mat-label>
        <input
          [disabled]="disableFields()"
          [readOnly]="disableFields()"
          (keyup)="checkPassword()"
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          placeholder="Lozinka"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint>
      </mat-form-field>
      <p *ngIf="!disableFields()">Lozinka mora sadržati:</p>
      <ul
        *ngIf="!disableFields()"
        style="margin-left: 20px; margin-bottom: 20px"
      >
        <li class="{{ passwordValidation.chars ? 'green' : 'red' }}">
          Najmanje 8 karaktera
        </li>
        <li class="{{ passwordValidation.uppercase ? 'green' : 'red' }}">
          Najmanje jedno veliko slovo
        </li>
        <li class="{{ passwordValidation.lowercase ? 'green' : 'red' }}">
          Najmanje jedno malo slovo
        </li>
        <li class="{{ passwordValidation.number ? 'green' : 'red' }}">
          Najmanje jedan broj
        </li>
        <li class="{{ passwordValidation.special ? 'green' : 'red' }}">
          Najmanje jedan specijalni karakter
        </li>
      </ul>
    </div>
    <mat-form-field class="example-full-width" *ngIf="disableFields()">
      <mat-label>Tip korisnika</mat-label>
      <input
        readonly
        matInput
        value="{{ getCodeName() }}"
        placeholder="Tip korisnika"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width" *ngIf="!disableFields()">
      <mat-label>Tip korisnika</mat-label>
      <mat-select formControlName="typeUcode">
        <mat-option
          *ngFor="let userType of data.userTypes"
          [value]="userType.ucode"
          >{{ userType.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="button-container">
    <div class="left-button">
      <button
        [disabled]="data.create || disableFields()"
        (click)="deleteUser()"
        mat-flat-button
        color="warn"
      >
        Obriši
      </button>
    </div>

    <div class="right-button">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!userForm.valid || (!checkPassword() && data.create)"
      >
        Snimi
      </button>
      <button mat-flat-button mat-dialog-close>Zatvori</button>
    </div>
  </div>
</form>
