/*
 *  Protractor support is deprecated in Angular.
 *  Protractor is used in this example for compatibility with Angular documentation tools.
 */
import {
  bootstrapApplication,
  provideProtractorTestingSupport,
} from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app/shared/routes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthInterceptor, SnackbarInterceptor } from './app/core/interceptors';

bootstrapApplication(AppComponent, {
  providers: [
    provideProtractorTestingSupport(),
    provideAnimations(),
    provideHttpClient(withInterceptors([AuthInterceptor, SnackbarInterceptor])),
    provideRouter(routes),
    MatSnackBar,
  ],
}).catch((err) => console.error(err));
