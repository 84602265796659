import { Injectable } from '@angular/core';
import { Features, Roles } from '@app/shared/types';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  checkPermission(feature: Features): boolean {
    const userRole = localStorage.getItem('role');

    if (userRole) {
      if (userRole === Roles.ADMIN) return true;
      if (userRole === Roles.USER) {
        return feature === Features.DASHBOARD || feature === Features.REPORTS;
      }
    }
    return false;
  }
}
