import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthService } from '@app/core/auth/auth.service';
import {LoginResponse} from "@shared/types";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-log-in',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css'],
})
export class LogInComponent {
  public loginForm = this.fb.group({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  loginUser(): void {
    this.authService.login(this.loginForm.value).subscribe({
      next: (res: LoginResponse) => {
        if (res.code == 'APP_USER' || res.code == 'SW_VENDOR') {
          this.snackBar.open("Ovaj tip korisnika ne može da se prijavi!",undefined, {
            duration: 3000,
          });
        } else {
          localStorage.setItem('access_token', res.message);
          localStorage.setItem('role', res.code);
          localStorage.setItem('username', <string>this.loginForm.get('username')?.value);
          this.router.navigate(['dashboard']);
        }
      },
      error: (e) => console.log(e),
    });
  }
}
