<mat-sidenav-container>
  <mat-sidenav mode="side" opened class="side-nav">
    <mat-nav-list>
      <section *ngFor="let page of pages">
        <a
          *checkPermissions="page.link"
          mat-list-item
          [routerLink]="page.link"
          [activated]="page.isActive"
          class="side-nav-link"
        >
          <article fxLayoutAlign="start center" fxLayoutGap="7">
            <mat-icon>{{ page.icon }}</mat-icon>
            <span>{{ page.title }}</span>
          </article>
        </a>
      </section>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="height: 92vh"
    ><ng-content></ng-content
  ></mat-sidenav-content>
</mat-sidenav-container>
