<app-breadcrumb
  currentPage="Izveštaji"
  [pages]="[{ title: 'Početna', link: '/dashboard' }]"
></app-breadcrumb>
<div class="header-row">
  <div class="header">Izveštaji</div>
</div>

<div class="table-container">
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="reports"
      matSort
      (matSortChange)="sortData($event)"
      class="mat-elevation-z0"
    >
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Ime</th>
        <td mat-cell *matCellDef="let report">{{ report.label }}</td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate">
          Datum izrade
        </th>
        <td mat-cell *matCellDef="let report">
          {{ report.creationDate | date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updateDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="updateDate">
          Datum izmene
        </th>
        <td mat-cell *matCellDef="let report">
          {{ report.updateDate | date }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="header-row-color"
      ></tr>
      <tr
        mat-row
        class="reports-table-row"
        *matRowDef="let row; columns: displayedColumns"
        (click)="openReportDetails(row)"
      ></tr>
    </table>
  </div>
</div>
