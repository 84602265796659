<section>
  <h4>{{ data.label }}</h4>
  <mat-form-field class="date">
    <mat-label class="date-label">Choose a date</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      (dateChange)="onDataChange($event.value)"
      [value]="data.state.value"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</section>
