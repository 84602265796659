import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-single-value-datetime',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-value-datetime.component.html',
  styleUrls: ['./single-value-datetime.component.css'],
})
export class SingleValueDatetimeComponent {
  @Input() public data: unknown;

  constructor() {}

  ngOnInit(): void {}
}
