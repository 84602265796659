import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  if (req.url.startsWith(authService.apiUrl)) {
    const authToken = authService.getToken();
    const headers = req.headers.set('token', `${authToken}`);
    req = req.clone({ headers });
  }
  if (req.url.startsWith(authService.reportsUrl)) {
    const headers = req.headers.set(
      'Authorization',
      'Basic ' + btoa('jasperadmin:G0rwTy5MDB')
    );
    req = req.clone({ headers });
  }

  return next(req).pipe(
    tap({
      next: () => {},
      error: (error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status !== 401) return;
          authService.logout();
        } else {
          authService.logout();
        }
      },
    })
  );
};
