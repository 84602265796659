import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BreadcrumbComponent} from "@layout/shared/components/breadcrumb/breadcrumb.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {Chart} from "@shared/types/Common.types";
import {environment} from "@environments/environment";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, BreadcrumbComponent, MatGridListModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  grafana = `<iframe src="${environment.grafanaUrl}/d/-VoHsr-Vk/elab?orgId=1&theme=light&kiosk" width="100%" height="1300" frameborder="0"></iframe>`
  charts: Chart[] = [
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687673029&to=1692709273029&theme=light&panelId=19" width="300" height="200" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687652661&to=1692709252661&theme=light&panelId=2" width="300" height="200" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame:  `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687636437&to=1692709236437&theme=light&panelId=18" width="300" height="200" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687609083&to=1692709209083&theme=light&panelId=8" width="300" height="200" frameborder="0"></iframe>`, cols: 1, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687609083&to=1692709209083&theme=light&panelId=10" width="600" height="300" frameborder="0"></iframe>`, cols: 2, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692688178781&to=1692709778781&theme=light&panelId=11" width="600" height="300" frameborder="0"></iframe>`, cols: 2, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687453056&to=1692709053056&theme=light&panelId=12" width="600" height="300" frameborder="0"></iframe>`, cols: 2, rows: 1},
    {frame: `<iframe src="${environment.grafanaUrl}/d-solo/-VoHsr-Vk/elab?orgId=1&from=1692687482757&to=1692709082757&theme=light&panelId=14" width="600" height="300" frameborder="0"></iframe>`, cols: 2, rows: 1},
  ];

  constructor(private sanitizer: DomSanitizer) { }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
