import { Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { PermissionGuard } from '@app/core/permissions/permission.guard';
import { LogInComponent } from '@app/layout/log-in/log-in.component';
import { MainPageComponent } from '@app/layout/main-page/main-page.component';
import { DashboardComponent } from '@app/layout/dashboard/dashboard.component';
import { ReportsComponent } from '@app/layout/reports/reports.component';
import { UsersComponent } from '@app/layout/users/users.component';
import { MonitoringComponent } from '@app/layout/monitoring/monitoring.component';
import { Features } from './types';
import { InstitutionsComponent } from '@app/layout/institutions/institutions.component';

export const routes: Routes = [
  { path: 'login', component: LogInComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainPageComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { feature: Features.DASHBOARD },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { feature: Features.REPORTS },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { feature: Features.USERS },
      },
      {
        path: 'monitoring',
        component: MonitoringComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { feature: Features.MONITORING },
      },
      {
        path: 'institutions',
        component: InstitutionsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { feature: Features.INSTITUTIONS },
      },
    ],
  },
];
