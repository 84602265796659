import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UserDialogData, UserRequest } from '@shared/types/User.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UserService } from '@core/services/user.service';
import { ConfirmationDialogComponent } from '@layout/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
  ],
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css'],
})
export class UserModalComponent {
  userForm: FormGroup;
  hide = true;
  passwordValidation = {
    chars: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  };
  get passwordInput() {
    return this.userForm.get('password');
  }
  constructor(
    public dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    private fb: FormBuilder,
    private userService: UserService,
    private matDialog: MatDialog
  ) {
    this.userForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      userName: [null, Validators.required],
      password: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      orgName: [null, Validators.required],
      typeUcode: [null, Validators.required],
    });
    if (!data.create) {
      this.userService.getUserDetails(data.username).subscribe({
        next: (user) => {
          this.userForm = this.fb.group({
            typeUcode: [user?.typeUcode, Validators.required],
            firstName: [user?.firstName, Validators.required],
            lastName: [user?.lastName, Validators.required],
            orgName: [user?.orgName, Validators.required],
            userName: [user?.userName, Validators.required],
            email: [user?.email, [Validators.required, Validators.email]],
            password: [null],
          });
          // this.userForm.get('typeUcode')?.setValue(user?.typeUcode);
        },
        error: (err) => {
          console.error('Something wrong occurred: ' + err);
        },
      });
    }
  }

  onSubmit() {
    if (this.data.create) {
      this.userService
        .createUser(new UserRequest(this.userForm.value))
        .subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: () => {},
        });
    } else {
      this.userService
        .updateUser(new UserRequest(this.userForm.value))
        .subscribe({
          next: () => {
            this.dialogRef.close({ edited: true });
          },
          error: () => {},
        });
    }
  }

  checkPassword() {
    const password = this.userForm.get('password')?.value;
    if (password) {
      this.passwordValidation.chars = password.length >= 8;
      this.passwordValidation.lowercase = /[a-z]/.test(password);
      this.passwordValidation.uppercase = /[A-Z]/.test(password);
      this.passwordValidation.number = /\d/.test(password);
      this.passwordValidation.special = /[^a-zA-Z0-9]/.test(password);
    }
    return (
      this.passwordValidation.chars &&
      this.passwordValidation.lowercase &&
      this.passwordValidation.uppercase &&
      this.passwordValidation.number &&
      this.passwordValidation.special
    );
  }

  disableFields() {
    if (this.data.create) {
      return false;
    }
    const accountType = this.userForm.get('typeUcode')?.value;
    return accountType == 'APP_USER';
  }

  deleteUser() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.userService.deleteUser(this.data.username).subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: (err) => {
            console.error('Something wrong occurred: ' + err);
          },
        });
      }
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  getCodeName() {
    let name = '';
    const accountType = this.userForm.get('typeUcode')?.value;
    this.data.userTypes.forEach((t) => {
      if (t.ucode == accountType) {
        name = t.name;
      }
    });
    return name;
  }
}
