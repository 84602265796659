import { inject } from '@angular/core';
import { HttpResponse, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export const SnackbarInterceptor: HttpInterceptorFn = (req, next) => {
  const snackBar = inject(MatSnackBar);
  return next(req).pipe(
    tap({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (resp: any) => {
        if (
          (req.method == 'POST' || req.method == 'PUT') &&
          !req.url.includes('getAccessToken')
        )
          if (resp instanceof HttpResponse && resp.status == 200) {
            snackBar.open(
              `${resp.body.message ?? 'Action successfully performed'}`,
              undefined,
              {
                duration: 3000,
              }
            );
          }
      },
      error: (error) => {
        if (!req.url.includes('getAccessToken')) {
          snackBar.open(`${error.status} ${error.error.message}`, undefined, {
            duration: 3000,
          });
        } else {
          snackBar.open('Pogrešni podaci!', undefined, {
            duration: 3000,
          });
        }
        console.error(error);
      },
    })
  );
};
