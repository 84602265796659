import { Features } from '@app/shared/types';
import { SideNavItem } from './side-nav.types';

export const items: SideNavItem[] = [
  {
    title: 'Početna',
    link: Features.DASHBOARD,
    isActive: false,
    icon: 'dashboard',
  },
  {
    title: 'Izveštaji',
    link: Features.REPORTS,
    isActive: false,
    icon: 'library_books',
  },
  {
    title: 'Korisnici',
    link: Features.USERS,
    isActive: false,
    icon: 'people',
  },
  {
    title: 'Monitoring',
    link: Features.MONITORING,
    isActive: false,
    icon: 'insert_chart_outlined',
  },
   {
    title: 'Lista institucija',
    link: Features.INSTITUTIONS,
    isActive: false,
    icon: 'view_list',
  },
];
