import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsService } from '@app/core/services/reports.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BreadcrumbComponent } from '@layout/shared/components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ReportUnit, Roles } from '@app/shared/types';
import { ReportDetailsComponent } from './reports-details-modal/report-details.component';
import { MatSortModule, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    RouterModule,
    MatDialogModule,
    BreadcrumbComponent,
    MatDialogModule,
    MatSortModule,
  ],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent {
  reports = new MatTableDataSource<ReportUnit>([]);
  displayedColumns: string[] = ['label', 'creationDate', 'updateDate'];
  userRole = localStorage.getItem('role');

  constructor(
    private readonly reportsService: ReportsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.reportsService.getAllReports().subscribe({
      next: (res) => {
        this.reports = new MatTableDataSource<ReportUnit>(
          this.filterReports(res.resourceLookup)
        );
      },
      error: (err) => {
        console.error('Something wrong occurred: ' + err);
      },
    });
  }

  sortData(sort: Sort) {
    const data = this.reports.data.slice();
    if (!sort.active || sort.direction === '') {
      this.reports.data = data;
      return;
    }
    this.reports.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.label, b.label, isAsc);
        case 'creationDate':
          return this.compareDate(a.creationDate, b.creationDate, isAsc);
        case 'updateDate':
          return this.compareDate(a.updateDate, b.updateDate, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a: Date, b: Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filterReports(reports: ReportUnit[]): ReportUnit[] {
    if (this.userRole !== Roles.ADMIN) {
      return reports.filter((report) => !report.uri.includes('Developer'));
    }
    return reports;
  }

  openReportDetails(report: ReportUnit): void {
    this.dialog.open(ReportDetailsComponent, {
      width: '700px',
      height: '700px',
      data: {
        report: report,
      },
      disableClose: true,
      autoFocus: false,
    });
  }
}
