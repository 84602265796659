import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { ValueState } from '@app/shared/types';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

interface SingleValueDateOutput {
  id: string;
  value: string;
}
@Component({
  selector: 'app-single-value-date',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
  ],
  providers: [MatDatepickerModule],
  templateUrl: './single-value-date.component.html',
  styleUrls: ['./single-value-date.component.css'],
})
export class SingleValueDateComponent {
  @Input() public data: {
    state: ValueState;
    label: string;
  };
  @Output() output = new EventEmitter<SingleValueDateOutput>();

  onDataChange(value: string) {
    this.output.emit({
      id: this.data.state.id,
      value: formatDate(value, 'yyyy-MM-dd', 'en-GB'),
    });
  }
}
