<app-breadcrumb
  currentPage="Lista institucija"
  [pages]="[{ title: 'Početna', link: '/dashboard' }]"
></app-breadcrumb>
<div class="header-row">
  <div class="header">Lista institucija</div>
</div>
<div class="table-container">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="institutions" class="mat-elevation-z0">
      <ng-container matColumnDef="institutionCode">
        <th mat-header-cell *matHeaderCellDef>Šifra laboratorije</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.institutionCode | dashIfEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="institutionName">
        <th mat-header-cell *matHeaderCellDef>Naziv laboratorije</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.institutionName | dashIfEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parentInstitution">
        <th mat-header-cell *matHeaderCellDef>Naziv ustanove</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.parentInstitution | dashIfEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="institutionType">
        <th mat-header-cell *matHeaderCellDef>Tip ustanove</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.institutionType | dashIfEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Lokacija</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.location | dashIfEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastTransactionTime">
        <th mat-header-cell *matHeaderCellDef>Datum i vreme sinhronizacije</th>
        <td mat-cell *matCellDef="let institution">
          {{ institution.lastTransactionTime | date | dashIfEmpty }}
        </td>
      </ng-container>

      <!-- Table rows -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="header-row-color"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      (page)="handlePageEvent($event)"
      [length]="2000"
      [pageSize]="pageSize"
      aria-label="Select page"
      [showFirstLastButtons]="true"
      [hidePageSize]="true"
    >
    </mat-paginator>
  </div>
</div>
