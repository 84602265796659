import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReportGenerateModalComponent } from '../report-generate-modal/report-generate-modal.component';
import { Report } from '@app/shared/types';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
interface PreviewModalData {
  report: Report;
  file: BlobPart;
}
@Component({
  selector: 'app-reports-preview-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    PdfViewerModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './reports-preview-modal.component.html',
  styleUrls: ['./reports-preview-modal.component.css'],
})
export class ReportsPreviewModalComponent {
  pdfSrc: string;
  zoom: number = 0.96;
  zoomLabel: string = `${1 * 100}%`;
  fileType: string = 'pdf';

  constructor(
    public dialogRef: MatDialogRef<ReportsPreviewModalComponent>,
    private dialog: MatDialog,

    //TODO Add type
    @Inject(MAT_DIALOG_DATA) public data: PreviewModalData
  ) {}

  ngOnInit(): void {
    const blob = new Blob([this.data.file], { type: 'application/pdf' });
    this.pdfSrc = window.URL.createObjectURL(blob);
  }

  zoomIn(): void {
    this.zoom = Math.round((this.zoom + 0.2) * 10) / 10;
    this.zoomLabel = `${this.zoom * 100}%`;
  }
  zoomOut(): void {
    this.zoom = Math.round((this.zoom - 0.2) * 10) / 10;
    this.zoomLabel = `${this.zoom * 100}%`;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  onFileTypeChange(fileType: string): void {
    this.fileType = fileType;
  }

  generateReportForDownload(): void {
    const report: Report = {
      reportUnitUri: this.data.report.reportUnitUri,
      async: true,
      freshData: false,
      saveDataSnapshot: false,
      outputFormat: this.fileType,
      interactive: true,
      ignorePagination: false,
      parameters: this.data.report.parameters,
    };

    this.dialog.open(ReportGenerateModalComponent, {
      width: '300px',
      height: '300px',
      data: {
        report: report,
        isPreview: false,
        fileType: this.fileType,
      },
      disableClose: true,
    });
  }
}
