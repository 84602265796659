<section class="single-select">
  <h4>{{ data.label }}</h4>
  <mat-form-field class="mat-select">
    <mat-label>{{ data.label }}</mat-label>
    <mat-select
      [(value)]="selected.value"
      (selectionChange)="onDataChange($event.value)"
    >
      <mat-option
        *ngFor="let option of data.state.options"
        [value]="option.value"
      >
        {{ option.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</section>
