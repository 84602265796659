export interface ResourceLookup {
  resourceLookup: ReportUnit[];
}

export interface ReportUnit {
  creationDate: Date;
  description: string;
  label: string;
  permissionMask: number;
  resourceType: string;
  updateDate: Date;
  uri: string;
  version: number;
}

export interface ReportDetails {
  alwaysPromptControls: boolean;
  controlsLayout: string;
  creationDate: Date;
  dataSource: DataSource;
  description: string;
  inputControlRenderView: string;
  inputControls: Reference[];
  jrxml: Jrxml;
  label: string;
  permissionMask: number;
  reportRenderingView: string;
  resources: Resources;
  updateDate: Date;
  uri: string;
  version: number;
}

interface DataSource {
  dataSourceReference: Reference;
}

interface Jrxml {
  jrxmlFileReference: Reference;
}

interface Resources {
  resource: Resource[];
}

interface Resource {
  name: string;
  file: File;
}

interface File {
  fileReference: FileReference;
}

interface FileReference {
  uri: string;
  version: number;
}

interface Reference {
  uri: string;
  version: number;
}

export interface InputControls {
  inputControl: InputControl[];
}

export interface InputControl {
  description: string;
  id: string;
  label: string;
  mandatory: boolean;
  masterDependencies: unknown[];
  readonly: boolean;
  slaveDependencies: unknown[];
  state: ValueState | OptionState;
  type: string;
  uri: string;
  visible: boolean;
  validationRules?: unknown[];
  dataType?: InputControlDataType;
}

interface InputControlDataType {
  strictMax: boolean;
  strictMin: boolean;
  type: string;
}

export interface ValueState {
  id: string;
  uri: string;
  value: unknown;
}

export interface OptionState {
  id: string;
  uri: string;
  options: Option[];
}

export interface Option {
  label: string;
  selected: boolean;
  value: string;
}

export interface ReportDetailsData {
  report: ReportUnit;
}

export interface Report {
  reportUnitUri: string;
  async: boolean;
  freshData: boolean;
  saveDataSnapshot: boolean;
  outputFormat: string;
  interactive: boolean;
  ignorePagination: boolean;
  parameters: ReportParameters;
  pages?: number;
}

export interface ReportParameters {
  reportParameter: ReportParameter[];
}

export interface ReportParameter {
  name: string;
  value: string | string[];
}

export interface ReportExecution {
  reportURI: string;
  requestId: string;
  status: string;
  exports: ReportExports[];
  totalPages?: number;
  errorDescriptor?: ErrorDescriptor;
}

export interface ReportExports {
  id: string;
  status: string;
  outputResource?: ReportOutputResource;
}

export interface ReportOutputResource {
  contentType: string;
  outputFinal: boolean;
  outputTimestamp: number;
}

export interface ErrorDescriptor {
  message: string;
}

export interface ReportGenerateData {
  report: Report;
  isPreview: boolean;
  fileType?: string;
}

export enum REPORT_STATUS {
  READY = 'ready',
  FAILED = 'failed',
  QUEUED = 'queued',
  EXECUTION = 'execution',
}
