import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {ResponseOnRequest, UserRequest, UserResponse, UserType} from "@shared/types/User.types";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getAllUsers() {
    return this.httpClient.get<UserResponse[]>(`${environment.apiUrl}/rest/ElabUserMgmt/listAllUsers`);
  }

  getAllUserTypes() {
    return this.httpClient.get<UserType[]>(`${environment.apiUrl}/rest/ElabUserMgmt/getUserTypes`);
  }

  getUserDetails(username: string) {
    return this.httpClient.get<UserResponse>(`${environment.apiUrl}/rest/ElabUserMgmt/getUserDetails?userName=${username}`);
  }

  createUser(request: UserRequest) {
    return this.httpClient.post<ResponseOnRequest>(`${environment.apiUrl}/rest/ElabUserMgmt/createUser`, request);
  }

  updateUser(request: UserRequest) {
    return this.httpClient.post<ResponseOnRequest>(`${environment.apiUrl}/rest/ElabUserMgmt/editUser`, request);
  }

  deleteUser(username: string) {
    return this.httpClient.get<ResponseOnRequest>(`${environment.apiUrl}/rest/ElabUserMgmt/deleteUser?userName=${username}`);
  }
}
