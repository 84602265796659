export enum Roles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum Features {
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  USERS = 'users',
  MONITORING = 'monitoring',
  INSTITUTIONS = 'institutions'
}
