export interface UserResponse {
  id: number
  typeUcode: string
  email: string
  firstName: string
  lastName: string
  orgName: string
  userName: string
  password: string
  tokenUid: string
  fromDt: string
  toDt: string
  notice: string
}

export interface UserType {
  id: number,
  ucode: string,
  name: string
}

export class UserRequest {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  userName: string | undefined
  password: string | undefined
  orgName: string | undefined
  typeUcode: string | undefined

  public constructor(init?: Partial<UserRequest>) {
    Object.assign(this, init);
  }
}

export interface ResponseOnRequest {
  id: number,
  code: number,
  message: string
}

export interface UserDialogData {
  username: string,
  userTypes: UserType[],
  create: boolean
}
